<template>
  <div class="sales-record-list">
    <PageTitle
      title="銷售紀錄列表"
      btn="新增"
      @btnClick="$router.push({ name: 'SalesRecordLogin' })"
    />

    <FiltersContainer>
      <BaseElInput
        v-model="orderSearch"
        clearable
        placeholder="輸入訂單編號"
        @keypress.enter.native="refresh"
        @clear="refresh"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          @click="refresh"
        />
      </BaseElInput>
      <BaseElInput
        v-model="phoneSearch"
        clearable
        placeholder="輸入電話號碼"
        @keypress.enter.native="refresh"
        @clear="refresh"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          @click="refresh"
        />
      </BaseElInput>
      <BaseElInput
        v-if="useBranchStore"
        v-model="branchStoreSearch"
        clearable
        placeholder="輸入操作門市名稱"
        @keypress.enter.native="refresh"
        @clear="refresh"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          @click="refresh"
        />
      </BaseElInput>
    </FiltersContainer>

    <section>
      <BaseTable
        v-loading="loading"
        :data="salesRecordList"
        empty-text="暫無數據"
      >
        <EmptyBlock slot="empty" />
        <BaseElTableColumn
          prop="orderNo"
          label="訂單編號"
          fixed="left"
          width="200"
          align="center"
        />
        <BaseElTableColumn
          fixed="left"
          prop="identity"
          label="姓名"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            {{
              scope.row.identity
                ? identityTrans(scope.row.identity)
                : scope.row.Member
                  ? scope.row.Member.UserInfo.name
                  : '非會員'
            }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn
          prop="identity"
          label="電話號碼"
          width="120"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.phone }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="totalItemsPrice" label="總額" align="center" />
        <BaseElTableColumn
          prop="totalDiscountPrice"
          label="已折金額"
          align="center"
        />
        <BaseElTableColumn
          prop="totalPaymentPrice"
          label="已付金額"
          align="center"
        />
        <BaseElTableColumn prop="status" label="狀態" align="center">
          <template slot-scope="scope">
            <Tag :type="tagType(scope.row.status)">
              {{ statusTrans(scope.row.status) }}
            </Tag>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn
          v-if="useBranchStore"
          prop="Branch"
          label="操作門市"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.Branch?.name || '-' }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn
          prop="createdAt"
          label="建立時間"
          width="115"
          align="center"
        >
          <template slot-scope="scope">
            {{ dateFormat(scope.row.createdAt) }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn label="操作" fixed="right" width="120" align="center">
          <template slot-scope="scope">
            <TableSettingIconButton @click="openRecordDialog(scope.row)" />
          </template>
        </BaseElTableColumn>
      </BaseTable>

      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="salesRecordCount"
        @pageChange="refresh"
      />
    </section>

    <!-- Dialog -->
    <el-dialog
      :visible.sync="passwordDialog"
      :close-on-click-modal="false"
      @close="password = ''"
    >
      <div slot="title">
        <span class="dialog-title">輸入密碼</span>
        <el-divider class="dialog-hr" />
      </div>
      <BaseElForm :model="password">
        <span class="dialog-content">密碼</span>
        <BaseElFormItem :rules="{ required: true }">
          <BaseElInput
            v-model="password"
            style="margin-top: 10px; margin-bottom: 20px"
            type="password"
            show-password
            placeholder="請輸入密碼"
          />
        </BaseElFormItem>
      </BaseElForm>
      <div class="flex-center">
        <BaseElButton
          type="primary"
          style="width: 100px; margin: 0 auto"
          @click="cancelSalesRecord"
        >
          確認
        </BaseElButton>
      </div>
    </el-dialog>

    <!-- Dialog -->
    <el-dialog
      :visible.sync="alertDialog"
      :close-on-click-modal="false"
      width="440px"
      @close="password = ''"
    >
      <div slot="title">
        <span class="dialog-title">提醒</span>
        <el-divider class="dialog-hr" />
      </div>
      <div class="dialog-content">
        尚未設定銷售紀錄作廢密碼 <br>
        請前往「基本參數設定 → 銷售紀錄參數設定」進行設定。
      </div>
      <div class="flex-center">
        <BaseElButton
          type="primary"
          style="width: 140px; margin-top: 40px"
          @click="alertDialog = false"
        >
          關閉
        </BaseElButton>
      </div>
    </el-dialog>

    <SalesRecordDialog
      v-if="showRecordDialog"
      :selectedTarget="selectRecord || {}"
      :useShopRebate="useShopRebate"
      @open-record-note-dialog="showRecordNoteDialog = true"
      @close-sales-record-dialog="showRecordDialog = false"
      @show-password-dialog="
        ;(showRecordDialog = false),
          config.useInvalidPassword
            ? (passwordDialog = true)
            : (alertDialog = true)
      "
    />

    <!-- Dialog -->
    <el-dialog
      class="sales-record-dialog"
      title="備註"
      :visible.sync="showRecordNoteDialog"
      :close-on-click-modal="false"
      width="592px"
    >
      <BaseElInput
        v-model="recordNote"
        type="textarea"
        :rows="10"
        placeholder="請輸入備註文字"
        maxlength="100"
        show-word-limit
      />
      <span slot="footer" class="dialog-footer">
        <BaseElButton
          plain
          style="width: 140px"
          @click="showRecordNoteDialog = false"
        >
          關閉
        </BaseElButton>
        <BaseElButton
          type="primary"
          style="width: 140px"
          @click="updateSaleRecordNote"
        >
          儲存
        </BaseElButton>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import TableSettingIconButton from '@/components/Button/TableSettingIconButton.vue'
import { mapGetters } from 'vuex'
// import DeleteDialog from '@/components/Dialog/DeleteDialog'
import EmptyBlock from '@/components/EmptyBlock.vue'
import {
  GetSalesRecord,
  GetSalesRecordCount,
  CancelSalesRecord,
  FindSalesRecord,
  UpdateSalesRecord,
  GetSalesConfig,
} from '@/api/sales'
import MixinFunc from '@/components/MixinFunc.vue'
// Utils
import { pageStartIndex } from '@/utils/table'
import { dialogTitle } from '@/utils/dialog'
import dayjs from '@/lib/dayjs'
import { find } from 'lodash'
import SalesRecordDialog from './components/SalesRecordDialog.vue'
import { checkUserFeature } from '@/store/modules/permission'
import { FindShopRebate } from '@/api/rebate'

export default {
  name: 'SalesRecordList',
  components: { EmptyBlock, SalesRecordDialog, TableSettingIconButton },
  mixins: [MixinFunc],
  data: () => ({
    loading: false,
    branchStoreSearch: null,
    phoneSearch: '',
    orderSearch: '',
    config: {},
    // Dialog
    alertDialog: false,
    passwordDialog: false,
    password: '',
    dialogType: 'create',
    deleteDialog: false,
    showRecordDialog: false,
    selectRow: null,
    selectRecord: null,
    selectUnitRecord: {},
    salesRecordCount: 0,
    tableOptions: {
      page: 1,
      pageLimit: 10,
    },
    shopRebate: {},

    salesRecordList: [],
    showRecordNoteDialog: false,
    recordNote: '',
  }),
  computed: {
    ...mapGetters(['shop', 'userPlanFeature', 'userFeatures']),

    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },

    useShopRebate () {
      return Boolean(
        checkUserFeature(
          this.userPlanFeature,
          this.userFeatures,
          'adminView.member.shopCashback',
        ) && this.shopRebate.id,
      )
    },

    useStorePermission () {
      return checkUserFeature(
        this.userPlanFeature,
        this.userFeatures,
        'admin.branch.adminUseStore',
      )
    },
    useBranchStore () {
      return checkUserFeature(
        this.userPlanFeature,
        this.userFeatures,
        'admin.branch.page',
      )
    },

    dialogTitle () {
      return dialogTitle(this.dialogType, {
        create: '新增銷售類別',
        update: '更新銷售類別',
      })
    },
  },

  async mounted () {
    await this.getSalseConfig()
    await this.refresh()

    if (
      checkUserFeature(
        this.userPlanFeature,
        this.userFeatures,
        'adminView.member.shopCashback',
      )
    ) {
      this.findShopRebate()
    }
  },

  methods: {
    async findShopRebate () {
      const [res, err] = await FindShopRebate({
        shopId: this.shop,
      })
      if (err) return this.$message.error(err)
      this.shopRebate = res
    },
    tagType (val) {
      let type = 'info'
      if (val === 'complete') type = 'action'
      if (val === 'invalid') type = 'danger'
      return type
    },
    //= > 付款方式翻譯
    statusTrans (text) {
      const dict = {
        complete: '已完成',
        invalid: '已作廢',
      }
      return dict[text]
    },

    //= > 付款方式翻譯
    paymentTrans (text) {
      const res = find(this.config.paymentTypes, { type: text })
      return res ? res.name : '未知'
    },

    //= > 身分翻譯
    identityTrans (text) {
      const dict = {
        frequenter: '熟客',
        platform: '平台戶',
        passerby: '過路客',
        foreigner: '外國客',
        bookinger: '預約客',
        other: '其他',
      }
      return dict[text]
    },

    recordDateFromat () {
      if (!this.selectRecord) return ''
      return dayjs(this.selectRecord.createdAt).format('YYYY/MM/DD HH:mm')
    },

    unitRecord () {
      const salesItem = this.selectRecord.SalesRecordItems
      const unitRecord = {}

      salesItem.forEach((item) => {
        // 如果有服務人員
        if (item.SalesUnit) {
          const name = item.SalesUnit.name
          if (unitRecord[name]) {
            unitRecord[name].items.push(item)
          } else {
            unitRecord[name] = { name, items: [] }
            unitRecord[name].items.push(item)
          }
        }

        if (!item.SalesUnit) {
          const name = 'nullUnit'
          if (unitRecord[name]) {
            unitRecord[name].items.push(item)
          } else {
            unitRecord[name] = { name, items: [] }
            unitRecord[name].items.push(item)
          }
        }
      })
      this.selectUnitRecord = unitRecord
    },

    async getSalseConfig () {
      try {
        const res = await GetSalesConfig({ shopId: this.shop })
        this.config = res
      } catch (error) {
        console.log(error)
        this.$message.error(error || error.message)
      }
    },

    async findSalesRecord () {
      try {
        this.selectRecord = await FindSalesRecord({
          shopId: this.shop,
          id: this.selectRow.id,
        })
        this.recordNote = this.selectRecord.note
        this.unitRecord()
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    async updateSalesRecord () {
      try {
        const record = await UpdateSalesRecord({
          shopId: this.shop,
          id: this.selectRow.id,
          note: this.recordNote,
        })
        this.selectRow.note = record.note
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    async refresh () {
      this.loading = true
      await this.getSalseRecord()
      await this.getSalesRecordCount()
      this.loading = false
    },

    async openRecordDialog (row) {
      this.selectRow = row
      await this.findSalesRecord()
      this.showRecordDialog = true
    },

    //= > 取得銷售類別
    async getSalseRecord () {
      const startIndex = this.pageStartIndex
      const limit = this.tableOptions.pageLimit
      try {
        const res = await GetSalesRecord({
          shopId: this.shop,
          start: startIndex,
          limit,
          phone: this.phoneSearch === '' ? undefined : this.phoneSearch,
          orderNo: this.orderSearch === '' ? undefined : this.orderSearch,
          branchName: this.branchStoreSearch || undefined,
        })
        this.salesRecordList = res
      } catch (error) {
        this.$message.error(error)
      }
    },

    //= > 取得銷售類別總數
    async getSalesRecordCount () {
      try {
        const res = await GetSalesRecordCount({
          shopId: this.shop,
          phone: this.phoneSearch === '' ? undefined : this.phoneSearch,
          orderNo: this.orderSearch === '' ? undefined : this.orderSearch,
          branchName: this.branchStoreSearch || undefined,
        })

        this.salesRecordCount = res
      } catch (error) {
        this.$message.error(error)
      }
    },

    async cancelSalesRecord () {
      try {
        if (this.password === '') {
          this.$message.warning('請輸入密碼 !')
          return
        }
        await CancelSalesRecord({
          shopId: this.shop,
          id: this.selectRow.id,
          password: this.password,
        })
        await this.refresh()
        this.$message.success('已作廢銷售紀錄 !')
        this.passwordDialog = false
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    async updateSaleRecordNote () {
      this.showRecordNoteDialog = false
      await this.updateSalesRecord()
      await this.findSalesRecord()
    },
  },
}
</script>

<style scoped lang="scss">
.unit-block {
  margin-bottom: 25px;
  .unit-name {
    background: #e7e7f3;
    padding: 5px 10px;
    margin-bottom: 8px;
  }

  .record-item {
    margin-bottom: 10px;

    .item-name {
      margin-right: 10px;
    }

    .item-quantity {
      flex-shrink: 0;
      font-weight: 500;
    }
  }
}

.payment {
  margin: 15px 0;
  .title {
    font-size: 21px;
  }
}

.dialog-title {
  font-size: 18px;
  @apply text-primary-100 font-bold;
}

.dialog-content {
  @apply text-center font-medium text-[18px] leading-[28px] text-gray-100;
  // font-family: Noto Sans TC;
  // font-style: normal;
}

.dialog-hr {
  @apply absolute left-0 right-0 top-[55px];
}

.price {
  font-weight: 500;
}

::v-deep .el-textarea {
  @apply w-full;
}
</style>
